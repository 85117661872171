import React from "react"

const SocialMedia = ({ socialMediaLinks }) => {
  //console.log("LINKS: ", socialMediaLinks)

  // Test mapping over social links
  // {
  //   socialMediaLink?.map(link => {
  //     console.log("LINK: ", link)
  //   })
  // }
  return (
    <div className="uk-flex">
      {socialMediaLinks?.map((item, index) => {
        //console.log("Link: ", item)

        return (
          <a
            key={index}
            uk-tooltip={`title: ${item.name}; pos: bottom-center`}
            href={item.url}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={item.name}
            alt={item.name}
            className="uk-icon-button uk-margin-small-right"
            uk-icon={item.icon}
          >
            {null}
          </a>
        )
      })}
    </div>
  )
}

export default SocialMedia
