import React, { useEffect, useState } from "react"
import Footer from "./footer"
import NavBar from "./nav"

import UIkit from "uikit"
import Icons from "uikit/dist/js/uikit-icons"
import "../styles/main.scss"
import "uikit/dist/css/uikit.min.css"

const Layout = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false)

  useEffect(() => {
    // trigger another render, reconciliation after the first mount, updating the dom with regards to rehydration
    setHasMounted(true)

    if (typeof window !== "undefined") {
      try {
        UIkit.use(Icons)
      } catch (e) {
        console.error(e)
      }
    }
  }, [])

  if (!hasMounted) {
    return null
  }

  return (
    <React.Fragment>
      <NavBar />
      {children}
      <Footer />
    </React.Fragment>
  )
}

export default Layout
